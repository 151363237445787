import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

function addCompare(state, payload) {
  let product = payload.product;
  let $root = payload.$root;
  let max = window.innerWidth < 768 ? 3 : 4;
  if (state.compares.length >= max) {
    $root.error_notification(`حداکثر انتخاب ${max} مورد امکان پذیر است`);
    return;
  }
  state.compares.push(product);
}

function removeCompare(state, payload) {
  let product = payload.product;
  let $root = payload.$root;
  state.compares = state.compares.filter((i) => i.id != product.id);
}

// Helper function for category
function addToAllParent(category, parent = null) {
  category.all_parent = parent;
  category.children.forEach((child) => {
    addToAllParent(child, category);
  });

  return category;
}

const header = {
  namespaced: true,
  state: () => ({
    menu: null,
    brands: null,
    cart: null,
    user: null,
    sliders: Array(1).fill(null),
    categories: [],
    blogCategories: Array(6).fill(null),
    popularBlogs: Array(6).fill(null),
    provinces: null,
    address: null,
    cities: null,
    minMax: null,
    categoryTitle: null,
    banners: [
      { position: "a1" },
      { position: "a1" },
      { position: "a1" },
      { position: "a1" },
      { position: "a2" },
      { position: "a3" },
      { position: "a3" },
      { position: "a3" },
      { position: "a3" },
      { position: "a3" },
      { position: "a3" },
    ],
    favorites: [],
  }),
  mutations: {
    setMenu(state, menu) {
      state.menu = menu;
    },
    setBrands(state, brands) {
      state.brands = brands;
    },
    setFavorites(state, favs) {
      state.favorites = favs;
    },
    setUser(state, user) {
      state.user = user;
    },
    setCart(state, cart) {
      state.cart = cart;
    },
    pushCart(state, cartItem) {
      if (!state.cart) {
        state.cart = [];
      }
      let flag = false;
      state.cart.forEach((item) => {
        if (item.id == cartItem.id) {
          item.quantity = cartItem.quantity;
          item.amount = cartItem.amount;
          item.amount = cartItem.amount;
          flag = true;
        }
      });

      if (!flag) {
        state.cart.push(cartItem);
      }
    },
    deleteCart(state, cartItem) {
      state.cart = state.cart.filter((item) => item.id != cartItem.id);
    },
    setSlider(state, sliders) {
      state.sliders = sliders;
    },
    setBanners(state, banners) {
      state.banners = banners;
    },
    setCategories(state, categories) {
      state.categories = categories.map((c) => {
        return addToAllParent(c);
      });
    },
    setBlogCategories(state, blogCategories) {
      state.blogCategories = blogCategories;
    },
    setPopularBlogs(state, popularBlogs) {
      state.popularBlogs = popularBlogs;
    },
    setProvinces(state, provinces) {
      state.provinces = provinces;
    },
    setAddress(state, address) {
      state.user.addresses.push(address);
    },
    setEditAddress(state, addressItem) {
      state.user.addresses = state.user.addresses.map((item, index) => {
        if (item.id == addressItem.id) {
          return addressItem;
        }
        return item;
      });
    },
    setCities(state, cities) {
      state.cities = cities;
    },
    setMinMax(state, minMax) {
      state.minMax = minMax;
    },
    setCategoryTitle(state, title) {
      state.categoryTitle = title;
    },
  },
  getters: {
    isAuth(state) {
      if (state.user === null) {
        return 0; // هنوز اطلاعات دریافت نشد
      }

      return Boolean(state.user);
    },
    userInfo(state) {
      return state.user;
    },
  },
};
const store = new Vuex.Store({
  state: {
    loading: false,
    settings: null,
    pendingCartToAdd: null,
    compares: [],
  },
  mutations: {
    resetCompare(state) {
      state.compares = [];
    },
    toggleCompare(state, payload) {
      let product = payload.product;
      let $root = payload.$root;
      let exists = state.compares.find((i) => i.id == product.id);
      if (exists) {
        removeCompare(state, { product, $root });
      } else {
        addCompare(state, { product, $root });
      }
    },
    addCompare(state, payload) {
      addCompare(state, payload);
    },
    removeCompare(state, payload) {
      removeCompare(state, payload);
    },
    increment(state) {
      state.count++;
    },
    showLoading(state, onlyWhenNotInitialized = true) {
      if (onlyWhenNotInitialized && this.getters.initialized) {
        return;
      }
      state.loading++;
    },
    hideLoading(state, onlyWhenNotInitialized = true) {
      if (
        onlyWhenNotInitialized &&
        this.getters.initialized &&
        state.loading === 0
      ) {
        return;
      }
      if (state.loading > 0) {
        state.loading--;
      }
    },
    setSettings(state, settings) {
      state.settings = settings;
    },
    setPendingCartToAdd(state, pendingCartToAdd) {
      state.pendingCartToAdd = pendingCartToAdd;
    },
  },
  getters: {
    initialized: (state) => {
      return state.header.user !== null && state.header.menu !== null;
    },
  },
  modules: {
    header,
  },
});

export default store;
