import { extend, localize   } from 'vee-validate';
import fa from "vee-validate/dist/locale/fa";

localize({
    fa: {
        ...fa,
        names: {
            mobile: 'شماره موبایل',
            phone_number: 'شماره تلفن',
            email: 'ایمیل',
            name: 'نام',
            text: 'متن',
            comment: 'نظر',
            last_name : 'نام خانوادگی',
            username:'نام کاربری',
            password:'کلمه عبور',
            company_name:'نام شرکت',
            ownership:'نوع مالکیت',
            categories:'دسته بندی',
            province:'استان',
            city_id:'شهر',
            product_varieties_count:'تنوع کالایی',
        }
    }
});
localize('fa');

extend('required', {
    validate(value) {
        return {
            required: true,
            valid: ['', null, undefined].indexOf(value) === -1
        };
    },
    computesRequired: true
});


extend('phone_number', value => {
    let regex = new RegExp('^(\\+98|0)?9\\d{9}$');
    if (regex.test(value)) {
        return true;
    }
    return 'فرمت تلفن همراه اشتباه است';
});