import Vue from "vue";
import "./custom";
import App from "./App.vue";
import VueRouter from "vue-router";
import routes from "./routes";
import config from './config'
import NumericDirective from './assets/libraries/numericDirectives'
import BootstrapVue from "bootstrap-vue"
import Notifications from "vue-notification";
import Multiselect from "vue-multiselect";
import moment from "moment-jalaali";
import Axios from "axios";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import draggable from "vuedraggable";
import swal from "sweetalert";
import './assets/libraries/veeValidate'
import './libraries/directives'
import './libraries/filters'
import './assets/styles.css'
import './assets/styles.scss'
import pagination from "laravel-vue-pagination"
import $ from 'jquery'
import * as VueMethods from './libraries/VueMethods'
import { FulfillingBouncingCircleSpinner } from 'epic-spinners'
import VueSlider from 'vue-slider-component'
import store from './libraries/store'
import './libraries/core'
import 'bootstrap'
import VueMeta from 'vue-meta'
import VueNestable from 'vue-nestable'


document.getScroll = function() {
  if (window.pageYOffset != undefined) {
    return [pageXOffset, pageYOffset];
  } else {
    var sx, sy, d = document,
        r = d.documentElement,
        b = d.body;
    sx = r.scrollLeft || b.scrollLeft || 0;
    sy = r.scrollTop || b.scrollTop || 0;
    return [sx, sy];
  }
}

if (window.location.href.indexOf("admin") > -1 || window.location.href.indexOf("panel") > -1) {


  // require("./assets/css/style-rtl.css");
  // require("./assets/plugins/horizontal-menu/dropdown-effects/fade-up.css");
  // require("./assets/css/default-rtl.css");
  // require("./assets/css/icons.css");
  // require("./assets/plugins/horizontal-menu/horizontal-rtl.css");
  // require("./assets/css/skins.css");
}
import ZoomOnHover from "vue-zoom-on-hover";
Vue.use(ZoomOnHover);
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(NumericDirective);
// require("bootstrap-vue/dist/bootstrap-vue.css");
Vue.use(VueNestable)

// import 'bootstrap'



// import VueNumericInput from "vue-numeric-input";


// import CKEditor from "ckeditor4-vue";

Vue.component("draggable", draggable);
Vue.component('vue-slider', VueSlider);
// Vue.use(CKEditor);
Vue.component("pagination", pagination);
window.swal = swal;
Vue.config.productionTip = false;
Vue.prototype.$axios = Axios;
Vue.prototype.$ = $;
Vue.prototype.moment = window.moment = moment;

Vue.component("date-picker", VuePersianDatetimePicker);
Vue.component("multiselect", Multiselect);
Vue.component('Sketch', require('vue-color/src/components/Sketch.vue'))
Vue.component("fulfilling-bouncing-circle-spinner", FulfillingBouncingCircleSpinner);
// Vue.use(VueNumericInput);
Vue.use(Notifications);
Vue.use(VueRouter);

Vue.prototype.$store = store;

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes,
});


Vue.prototype.console = {
  log : (value) => {
    console.log(value)
  }
}

Vue.mixin({
  data() {
    return {
      disabledSliders: []
    }
  },
  methods: {
    initSwiper(name, kill = false, callback) {
      if (kill) {
        this.disabledSliders.push(name)
      }
      setTimeout(() => {
        if (kill) {
          this.disabledSliders = this.disabledSliders.filter(item => item != name)
        }
        setTimeout(() => {
          callback()
        }, 0)
      }, 0)
    },
  }
})

new Vue({
  data() {
    return {
      baseUrl: config.baseUrl,
      allPermissions: [],
      adminProfile: [],
      currentAdminPermissions: [],
      colorLoading:'var(--color-themeBlue)',
      transitionName: 'drain'
    };
  },

  created() {
    this.$axios.defaults.baseURL = config.baseUrl
    this.$axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          this.$notify({
            group: "foo",
            title: "پیام سیستم",
            text: error.response.data.message,
            type: "error",
            duration: "5000",
          });
          if(this.$route.fullPath.includes('/admin')) {
            this.deleteCookie('adminAuthorization')
            router.push('/admin/login')
          } else if(this.$route.fullPath.includes('/panel')) {
            this.deleteCookie('sellerAuthorization')
            router.push('/seller')
          } else {
            this.deleteCookie('userAuthorization')
            // TODO پیام بدیم
          }
          // this.deleteCookie("adminAuthorization");

          // router.push("/admin/login");
        }
        throw error;
      }
    );


    if(window.location.href.indexOf("admin") > -1) {
      if (this.getCookie("adminAuthorization")) {
        this.$axios.defaults.headers.common["Authorization"] = this.getCookie(
          "adminAuthorization"
        );
      } else {
        router.push('/admin/login')
      }
    } else if(window.location.href.indexOf("panel") > -1) {
      if (this.getCookie("sellerAuthorization")){
        this.$axios.defaults.headers.common["Authorization"] = this.getCookie(
          "sellerAuthorization"
        );
      } else {
        router.push('/seller')
      }
    } else {
      if (this.getCookie("userAuthorization")){
        this.$axios.defaults.headers.common["Authorization"] = this.getCookie(
            "userAuthorization"
        );
      }
    }

  },

  methods: {
    ...VueMethods
  },
  watch: {
    $route(to) {
      if (to.path !== '/') {
        this.transitionName = 'drain';
      } else {
        this.transitionName = 'drain'
      }
      if (to.path !== '/' && window.innerWidth < 700) {
        this.scrollTo('.top-header-L')
      }


      document.title = to.meta.title || "آلبا مارکت  ";
      setTimeout(() => {
        document.title = to.meta.title || "آلبا مارکت  "; // for bug
      })
      // if (this.getCookie("adminAuthorization")) {
      //   this.$axios.defaults.headers.common["Authorization"] = this.getCookie(
      //     "adminAuthorization"
      //   );
      // } else {
      //   this.$router.push("/admin/login");
      // }
    },
  },
  render: (h) => h(App),
  router: router,
}).$mount("#app");
