// غیر فعال کردن اسلایدر وقتی موس بره روش
import $ from "jquery";

$(document).ready(function () {
    $(".swiper-container").hover(function() {
        (this).swiper.autoplay.stop();
    }, function() {
        (this).swiper.autoplay.start();
    })
});

window.clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};
